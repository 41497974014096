import React, { useEffect, useState } from 'react'
import Select from '../../../component/formfields/Select';
import Input from '../../../component/formfields/Input';
import SubmitButton from '../../../component/buttons/SubmitButton';
import ReturnButton from '../../../component/buttons/ReturnButton';
import UploadField from '../../../component/formfields/UploadField';
import axiosInstance from '../../../utils/axiosInstance';
import { useSelector } from 'react-redux';
import { createToast } from '../../../redux/actions/toast';
import Label from '../../../component/formfields/Label';
import DistancePreview from './DistancePreview';
import { FaChevronRight, FaChevronDown, FaTimes } from 'react-icons/fa';

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { nl } from "date-fns/locale"; // Import the Dutch locale

function RideFormUpload({ addressOptions, carOptions, driverOptions }) {
    const user = useSelector(state => state.auth.user);

    // section to render a form to add a new ridepoint
    // used in RidepointCreate.jsx page component
    const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);

    const [uploadedImage, setUploadedImage] = useState(null);
    const [previewURL, setPreviewURL] = useState('');

    // data post format
    // {
    //     "startmilage": 12730,
    //     "endmilage": 12730,
    //     "startaddress": 4,
    //     "endaddress": 8,
    //     "car": 26,
    //     "driver": 4,
    //     "date": "2024-06-17T10:00:00Z",
    //     "reason": "Business meeting"
    // }
    const [startmilage, setStartmilage] = useState(0);
    const [endmilage, setEndmilage] = useState(0);
    const [startaddress, setStartaddress] = useState("");
    const [endaddress, setEndaddress] = useState("");
    const [car, setCar] = useState("");
    const [driver, setDriver] = useState("");
    const [date, setDate] = useState(new Date());
    const [rideReason, setRideReason] = useState('Visit customer');
    // console.log(endmilage)


    useEffect(() => {
        const uploadImage = async () => {
            // upload image to vision API and detect text
            if (uploadedImage) {
    
                // Create a FormData object
                const postData = new FormData();
                postData.append('image', uploadedImage);
    
                try {
                    const response = await axiosInstance.post('/api/ocr/image-upload/', postData);
                    // check if the response data filtered_snippet item is 
                    // 1) not empty
                    // 2) a number between 10.000 and 1.000.000
                    // if so, set result to form data, so it can be saved with the ride

                    const snippet = Number(response.data.filtered_snippet.text); // convert to number 

                    if (snippet) {
                        if (snippet > 10000 && snippet < 1000000) {
                            setEndmilage(snippet);
                        }
                    }

                } catch (error) {
                    console.error('Error uploading data:', error);
                }
            }
        };
    
        uploadImage(); // Call the async function inside useEffect
    
    }, [uploadedImage]);
    



    useEffect(() => {
        // update driver to logged in user
        setDriver(user?.id)

    }, [driverOptions])


    useEffect(() => {
        if (!car) return;

        // fetch latest ride, prefill form with data 
        axiosInstance.get(`/api/rides?car=${car}&latest=true`)
            .then(res => {
                const data = res.data[0];
                if (!data) return;

                setStartaddress(() => (data.endaddress))
                setStartmilage(data.endmilage)
            })
            .catch(err => {
                console.log(err);
            })
    }, [car])


    // handle form submit, dispatch form data to api
    // on success, redirect to ridepoints list
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a FormData object
        const postData = new FormData();
       
        postData.append('startmilage', startmilage);
        postData.append('endmilage', endmilage);
        postData.append('startaddress', startaddress);
        postData.append('endaddress', endaddress);
        postData.append('car', car);
        postData.append('driver', driver);
        postData.append('date', date);
        postData.append('reason', rideReason);


        // if (uploadedImage) {
        //     // Append the uploaded image
        //     postData.append('image', uploadedImage);
        // }

        try {
            const response = await axiosInstance.post('/api/cars/', postData);
            createToast('Ride created', 'success');
            window.location.href = '/rides';
        } catch (error) {
            createToast('Failed to create ride', 'error');
        }


        // const postData = {
        //     startmilage: startmilage,
        //     endmilage: endmilage,
        //     startaddress: startaddress,
        //     endaddress: endaddress,
        //     car: car,
        //     driver: driver,
        //     date: date,
        //     reason: rideReason
        // }

        // axiosInstance.post('/api/rides/', postData)
        //     .then(res => {
        //         createToast('Ride created', 'success');
        //         window.location.href = '/rides';
        //     })
        //     .catch(err => {
        //         createToast('Failed to create ride', 'error');
        //     })

    }



    return (
        <form onSubmit={e => handleSubmit(e)}>
            <div className="flex flex-wrap -mx-3">

                {/* Car */}
                <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                    <Select
                        name="car"
                        label="Car"
                        placeholder="Select car"
                        required
                        onChangeHandler={e => setCar(e.target.value)}
                        options={carOptions}
                        value={car}
                    />
                </div>

                {/* Date */}
                <div className="w-full md:w-1/4 px-3 md: mb-6 mt-6 md:mb-0">
                    <Label text="Date" />
                    <DatePicker
                        selected={date}
                        onChange={(d) => setDate(d)}
                        className="w-full px-4 py-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        locale={nl} // Set the locale to Dutch
                        dateFormat="yyyy/MM/dd" // Set your desired date format
                    />
                </div>




                <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                    {uploadedImage ? (
                        <div className="relative">
                            <img src={previewURL} alt="Preview" className="w-64 h-64 object-cover rounded-lg mx-auto" />
                            <button
                                type="button"
                                onClick={() => {
                                    setUploadedImage(null);
                                    setPreviewURL('');
                                }}
                                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full"
                            >
                                <FaTimes />
                            </button>
                        </div>
                    ) : (
                        <div className="">
                            <UploadField setUploadedImage={setUploadedImage} setPreviewURL={setPreviewURL} />
                        </div>
                    )}
                </div>

                <div className="w-full flex items-center justify-between">
                    <button
                        type="button"
                        onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
                        className="flex items-center ml-4 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {isMoreOptionsOpen ? <FaChevronDown /> : <FaChevronRight />}
                        <span className="ml-1">More Options</span>
                    </button>
                </div>
                {isMoreOptionsOpen && (
                    <div className="w-full flex flex-wrap ">

                        <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                            <Input
                                name="reason"
                                label="Ride reason"
                                type="text"
                                text=""
                                onChangeHandler={e => setRideReason(e.target.value)}
                                value={rideReason}
                            />
                        </div>

                        <div className="w-full md:w-1/4 px-3 md: mb-6 mt-6 md:mb-0">
                            <Select
                                name="driver"
                                label="Driver"
                                placeholder="Select driver"
                                onChangeHandler={e => setDriver(e.target.value)}
                                options={driverOptions}
                                value={driver}
                            />
                        </div>
                    </div>
                )}


                <div className="w-full md:w-1/2">
                    {/* start address point */}

                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">

                        <Select
                            name="startaddress" label="Address" placeholder="Select start address"
                            required
                            onChangeHandler={e => setStartaddress(e.target.value)}
                            options={addressOptions}
                            value={startaddress}
                        />
                    </div>

                    {/* Milage */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Input
                            name="startmilage"
                            label="Start milage"
                            placeholder="Enter start milage"
                            type="number"
                            required
                            onChangeHandler={e => setStartmilage(e.target.value)}
                            value={startmilage}
                        />
                    </div>
                </div>

                <div className="w-full md:w-1/2">
                    {/* start address point */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Select
                            name="endaddress" label="End address" placeholder="Select end address"
                            required
                            onChangeHandler={e => setEndaddress(e.target.value)}
                            options={addressOptions}
                            value={endaddress}
                        />
                    </div>

                    {/* Milage */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Input
                            name="endmilage"
                            label="End milage"
                            placeholder="Enter end milage"
                            type="number"
                            required
                            onChangeHandler={e => setEndmilage(e.target.value)}
                            value={endmilage}
                        />
                    </div>

                    {/* Distance */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Label text="Distance" />
                        <DistancePreview start={startmilage} end={endmilage} />
                    </div>
                </div>
            </div >


            <div className="flex items-center justify-center mt-12">
                <SubmitButton text="Save" />
                <ReturnButton linkurl="/ridepoints" text="Cancel" />
            </div>
        </form >
    )
}

export default RideFormUpload