import dayjs from 'dayjs'
import { jwtDecode } from "jwt-decode";

export const isExpired = (token) => {

    const user = jwtDecode(token);

    // Check if token is expired
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    return isExpired;
}


export const refreshAccessToken = () => {
    axios.post('/api/v1/auth/jwt/refresh/', { "refresh_token": refresh })
        .then((res) => {

            localStorage.setItem('access', res.data.access_token);
            return res.data.access_token;
        })

}